import React from 'react';
import '../static/TOS.css';
import '../static/Schedule.css';
import Map from '../images/map.jpg';

// TODO
// add modifiable object for next 12 months

const Schedule = () => {

  const MAPS_URL = "https://maps.app.goo.gl/iL9YxbT7V2NxVivA8";

  return (
    <div className="force-w">
      <section className='darkSection' id='schedule'>
        <h3 className='siteHeadings'>Schedule</h3>
        
        <div className='schedule-container'>
          <div className='schedule-col'>
            <div className='schedule-col-item'>
              <h2>Sunday Service</h2>
              <ul className='schedule-list'>
                <li>Chinese — 9:30 AM</li>
                <li>English — 11:30 AM</li>
                <br />                
                <li>
                  Combined — 10:00 AM, <strong>only for certain holidays</strong> <br />
                  i.e. Easter, Mother's day, Father's day, Thanksgiving and Christmas
                </li>
              </ul>
            </div>

            <div className='schedule-col-item'>
              <h2>Prayer Meeting (Tue / Wed)</h2>
              <ul className='schedule-list'>
                <li>English — Tuesdays 7:30 PM</li>
                <li>Chinese — Wednesdays 7:30 PM</li>
              </ul>
            </div>

            <div className='schedule-col-item'>
              <h2>Fellowship (Friday)</h2>
              <ul className='schedule-list'>
                <li>English / Chinese — 7:30 PM (doors open at 6:30 PM)</li>
              </ul>
            </div>
          </div>

          <div className='schedule-col'>
            <div className='schedule-col-item'>
              <h2>April</h2>
              <ul className='schedule-list'>
                <li>04/06 — Separate</li>
                <li>04/13 — Separate</li>
                <li><b>04/20 - Easter - Combined</b></li>
                <li>04/27 — Separate</li>
              </ul>
            </div>

            <div className='schedule-col-item'>
              <h2>Address</h2>
              <ul className='schedule-list'>
                <li>
                  <a href={MAPS_URL} target={'_blank'}>
                    2287 East 15th St<br />
                    Brooklyn, NY 11229
                  </a>
                </li>
              </ul>
              <img src={Map} className="roundi"></img>
            </div>
          </div>
        </div>
        
      </section>
    </div>
  );
}

export default Schedule;
